import Modifier from 'ember-modifier';
import { Chart } from 'chart.js';
import isTesting from 'nightwatch-web/utils/is-testing';
import alphaBlend from '../helpers/alpha-blend';
import { NOTE_IMAGE } from '../utils/base64-images';

/**
  @class ChartJs

  Renders Chart.js charts

  @argument chartOptions {Object} Chart.js data and options
  @argument skipAnimation {Boolean} Skip animating the chart when updating
 */
export default class ChartJs extends Modifier {
  chart = null;

  didReceiveArguments() {
    if (this.chart) return this.updateChart();

    this.renderChart();
  }

  willRemove() {
    this.chart?.destroy();
  }

  renderChart() {
    const { chartOptions } = this.args.named;
    this.chart = new Chart(this.element, {
      ...chartOptions,
      options: {
        ...chartOptions.options,
        animation: isTesting ? false : chartOptions.options.animation,
        plugins: {
          tooltip: {
            enabled: false,
            external: (context) => this.renderCustomTooltip(context),
          },
        },
      },
    });
  }

  updateChart() {
    const { chartOptions, skipAnimation } = this.args.named;
    this.chart.data = chartOptions.data;
    this.chart.options = {
      ...chartOptions.options,
      plugins: {
        tooltip: {
          enabled: false,
          external: (context) => this.renderCustomTooltip(context),
        },
      },
    };
    this.chart.update(skipAnimation ? 'none' : null);
  }

  // Custom Tooltip Rendering
  renderCustomTooltip(context) {
    const MAX_LINE_LENGTH = 75;
    function formatText(inputText, maxLineLength) {
      return inputText
        .split('\n')
        .map((line) => {
          let formattedLine = '';
          let currentLength = 0;

          line.split(' ').forEach((word) => {
            if (currentLength + word.length > maxLineLength) {
              formattedLine += '<br>' + word + ' ';
              currentLength = word.length + 1; // +1 for the space
            } else {
              formattedLine += word + ' ';
              currentLength += word.length + 1; // +1 for the space
            }
          });

          return formattedLine.trim();
        })
        .join('<br>');
    }
    const { chart, tooltip } = context;

    // Tooltip is hidden
    if (!tooltip.opacity) {
      const tooltipEl = document.querySelector('.chartjs-tooltip');
      if (tooltipEl) tooltipEl.style.opacity = '0';
      return;
    }
    const graphNotes = tooltip.dataPoints?.find(
      (point) => point.dataset.type === 'nwNote'
    );

    // Create tooltip element if it doesn't exist
    let tooltipEl = document.querySelector('.chartjs-tooltip');
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.className = 'chartjs-tooltip';
      Object.assign(tooltipEl.style, {
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        padding: '5px',
        borderRadius: '3px',
        pointerEvents: 'none',
        zIndex: 2,
      });
      document.body.appendChild(tooltipEl);
    }
    let titleHTML;
    if (!graphNotes) {
      titleHTML = tooltip.title.map((title) => {
        return `<div style="font-weight: bold; margin-bottom: 8px;">${title}</div>`;
      });
    } else {
      //title is the first line of body when notes are present
      titleHTML = `<div style="font-weight: bold; margin-bottom: 8px;">${tooltip.body[0].lines[0].slice(
        1,
        -4
      )}</div>`;
      tooltip.body[0].lines.shift();
    }
    const legendSize = '15px';
    let bodyHTML = tooltip.body
      .map((item, index) => {
        if (!item.lines.length) return '';
        const white = '#ffffff';
        const bgColor = tooltip.labelColors[index]?.backgroundColor
          ? alphaBlend(tooltip.labelColors[index].backgroundColor, white)
          : null;
        if (bgColor) {
          const borderColor = tooltip.labelColors[index]?.borderColor
            ? alphaBlend(tooltip.labelColors[index].borderColor, white)
            : null;
          const borderRadius =
            tooltip.labelPointStyles[index].pointStyle === 'rect' ? '0' : '50%';
          return `
      <div style="display: flex; align-items: center; margin-bottom: 5px;">
        <div style="
        width: ${legendSize};
        height: ${legendSize};
        background-color: ${bgColor};
        ${borderColor ? `border-color: ${borderColor};` : ''}
        margin-right: 10px;
        border-radius: ${borderRadius};">
        </div>
        <span>${item.lines}</span>
      </div>`;
        } else {
          return `
      <div style="margin-bottom: 5px;">
        <span>${item.lines}</span>
      </div>`;
        }
      })
      .join('');

    if (graphNotes) {
      bodyHTML += `
      <div style="display: flex; align-items: center; margin-bottom: 3px;">
        <img src="${NOTE_IMAGE}" style="
        width: ${legendSize};
        height: ${legendSize};
        margin-right: 10px;
        border-radius: 50%;"
        alt="notes">
        <div style="font-weight: bold; margin-top: 3px;">Notes:</div>
      </div>
        <span>${formatText(graphNotes.raw.txt, MAX_LINE_LENGTH)}</span>`;
    }

    tooltipEl.innerHTML = titleHTML + bodyHTML;
    const position = chart.canvas.getBoundingClientRect();
    const tooltipWidth = tooltipEl.offsetWidth;
    const screenWidth = window.innerWidth;

    let left = position.left + tooltip.caretX;

    // Adjust position if tooltip goes off the screen
    if (left + tooltipWidth > screenWidth) {
      left = position.left + tooltip.caretX - tooltipWidth;
    }

    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = `${left}px`;
    tooltipEl.style.top = `${position.top + tooltip._eventPosition.y}px`;
  }
}
