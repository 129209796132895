import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { isPresent } from '@ember/utils';

export default class ConfirmAccountController extends Controller {
  @service fetch;
  @service notifications;
  @service session;

  queryParams = ['token'];
  t = null;

  @computed('token')
  get tokenPresent() {
    return this.token && isPresent(this.token);
  }

  @action
  resendLink() {
    if (this.emailSent) {
      return;
    }

    this.fetch
      .post(
        `user/resend_confirmation_email?email=${this.session.user.email}&access_token=${this.session.token}&old_account=true`
      )
      .then(() => {
        this.set('emailSent', true);
      })
      .catch(() => {
        this.notifications.error(
          'Error while sending the e-mail. Please contact support.',
          { autoClear: true, clearDuration: 10000 }
        );
      });
  }
}
