function hexToRgba(hex) {
  let r = 0, g = 0, b = 0, a = 1;

  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7 || hex.length === 9) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
    if (hex.length === 9) {
      a = parseInt(hex.slice(7, 9), 16) / 255;
    }
  }

  return { r, g, b, a };
}

function parseColor(color) {
  if (color.startsWith('#')) {
    return hexToRgba(color);
  } else if (color.startsWith('rgba')) {
    const rgba = color.match(/rgba\((\d+), (\d+), (\d+), (\d+(\.\d+)?)\)/);
    return {
      r: parseInt(rgba[1]),
      g: parseInt(rgba[2]),
      b: parseInt(rgba[3]),
      a: parseFloat(rgba[4])
    };
  } else if (color.startsWith('rgb')) {
    const rgb = color.match(/rgb\((\d+), (\d+), (\d+)\)/);
    return {
      r: parseInt(rgb[1]),
      g: parseInt(rgb[2]),
      b: parseInt(rgb[3]),
      a: 1
    };
  }
  throw new Error('Invalid color format');
}

export function alphaBlend(fgColor, bgColor) {
  if (!fgColor || !bgColor) {
    return;
  }
  const fg = parseColor(fgColor);
  const bg = parseColor(bgColor);

  const r = Math.round(fg.r * fg.a + bg.r * (1 - fg.a));
  const g = Math.round(fg.g * fg.a + bg.g * (1 - fg.a));
  const b = Math.round(fg.b * fg.a + bg.b * (1 - fg.a));

  return `rgb(${r}, ${g}, ${b})`;
}

export default alphaBlend;

// Example usage:
// const blendedColor = alphaBlend('rgba(255, 0, 0, 0.3)', '#000000');
