import ENV from 'nightwatch-web/config/environment';

export function initialize() {
  if (
    ENV.environment === 'production' &&
    typeof console !== 'undefined'
  ) {
    ['log', 'warn', 'info', 'debug'].forEach((method) => {
      console[method] = function () {}; // disable console in production
    });
  }
}

export default {
  name: 'disable-console',
  initialize,
};
